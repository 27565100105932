h1, h2, h3, h4, h5, h6 {
  font-family: Open Sans, sans-serif;
}

@keyframes stars {
  from {
    background-position: 0%;
  }

  to {
    background-position: 1500px;
  }
}

img {
  display: inline-block;
}

.trailer-section {
  color: #fff;
  background: #1a1c1d;
  padding: 40px 0 0;
}

@media (width >= 768px) {
  .trailer-section {
    padding: 50px 0 0;
  }
}

.trailer-section p {
  text-transform: uppercase;
  color: #666;
  font-size: 14px;
}

@media (width >= 768px) {
  .trailer-section p {
    font-size: 16px;
  }
}

.trailer-section p img {
  vertical-align: bottom;
  height: 25px;
  margin: 0 5px 0 0;
}

@media (width >= 768px) {
  .trailer-section p img {
    height: 30px;
    margin: 0 7px 0 0;
  }
}

.trailer-section h1 {
  margin: 0 auto 25px;
  font: 400 19px / 1.1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .trailer-section h1 {
    margin: 0 auto 35px;
    font-size: 30px;
  }
}

@media (width >= 1024px) {
  .trailer-section h1 {
    font-size: 38px;
  }
}

.trailer-section h1 strong {
  font-size: 36px;
  line-height: 1.1em;
}

@media (width >= 768px) {
  .trailer-section h1 strong {
    font-size: 60px;
  }
}

@media (width >= 1024px) {
  .trailer-section h1 strong {
    font-size: 80px;
  }
}

.trailer-section .ipad-phone {
  width: 100%;
  max-width: 330px;
  margin: 0 auto -10px;
  position: relative;
}

@media (width >= 768px) {
  .trailer-section .ipad-phone {
    max-width: 560px;
    margin: 0 auto -20px;
  }
}

@media (width >= 1024px) {
  .trailer-section .ipad-phone {
    max-width: 710px;
    margin: 0 auto -25px;
  }
}

.trailer-section .ipad-phone .preview {
  width: 110px;
  margin: 0;
  position: absolute;
  top: 35%;
  left: 27%;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  .trailer-section .ipad-phone .preview {
    width: 130px;
    left: 31%;
  }
}

@media (width >= 1024px) {
  .trailer-section .ipad-phone .preview {
    width: 140px;
    top: 37%;
    left: 32%;
  }
}

.trailer-section .ipad-phone i {
  text-align: center;
  cursor: pointer;
  background: #0b76db;
  border: 3px solid #fff;
  border-radius: 50%;
  outline: none;
  width: 58px;
  height: 58px;
  font-size: 25px;
  line-height: 52px;
  position: absolute;
  top: 48%;
  left: 42%;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  .trailer-section .ipad-phone i {
    width: 78px;
    height: 78px;
    font-size: 35px;
    line-height: 72px;
  }
}

.trailer-section .ipad-phone i:hover {
  background: #258ff4;
}

.trailer-section.kids-app {
  background: #13101e;
  position: relative;
}

.trailer-section.kids-app:before {
  content: "";
  background: url("https://dpwjbsxqtam5n.cloudfront.net/app/for-kids/stars.jpg") top / 1000px;
  height: 100%;
  animation: 100s linear infinite stars;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 768px) {
  .trailer-section.kids-app:before {
    background-size: 1140px;
  }
}

.trailer-section.kids-app h1 {
  margin: 0 auto 25px;
  font: 900 28px / 1.2em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .trailer-section.kids-app h1 {
    margin: 0 auto 35px;
    font-size: 36px;
  }
}

@media (width >= 1024px) {
  .trailer-section.kids-app h1 {
    margin: 0 auto 47px;
    font-size: 46px;
  }
}

.trailer-section.kids-app p img {
  height: 25px;
  margin: 0 0 5px;
}

@media (width >= 768px) {
  .trailer-section.kids-app p img {
    height: 38px;
  }
}

.trailer-section.kids-app .ipad-phone .preview {
  left: 57%;
}

@media (width >= 768px) {
  .trailer-section.kids-app .ipad-phone .preview {
    left: 53%;
  }
}

.trailer-section.kids-app .ipad-phone i {
  background: #b500ff;
}

.trailer-section.kids-app .ipad-phone i:hover {
  background: #c433ff;
}

.trailer-section.kids-app .anchored-image {
  height: 60%;
  position: absolute;
}

@media (width >= 1024px) {
  .trailer-section.kids-app .anchored-image {
    height: 80%;
  }
}

.trailer-section.kids-app .anchored-image.left-image {
  bottom: -30px;
  left: 0;
}

.trailer-section.kids-app .anchored-image.right-image {
  top: 0;
  right: 0;
}

.download-pitch {
  background: #eff0f1;
  padding: 30px 15px 40px;
}

@media (width >= 768px) {
  .download-pitch {
    padding: 40px 0;
  }
}

.download-pitch p {
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
  font: 400 14px / 1.6em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .download-pitch p {
    max-width: 100%;
    font-size: 17px;
  }
}

@media (width >= 1024px) {
  .download-pitch p {
    font-size: 19px;
  }
}

.download-pitch p a {
  color: inherit;
}

.download-pitch p.small {
  font-size: 12px;
}

.download-pitch .download-badge img {
  width: 150px;
  margin: 20px 4px;
}

@media (width >= 768px) {
  .download-pitch .download-badge img {
    width: 160px;
    margin: 30px 15px;
  }
}

@media (width >= 1024px) {
  .download-pitch .download-badge img {
    width: 180px;
  }
}

.download-pitch.kids-app {
  background: #f9e602;
  padding-bottom: 0;
}

.curved-bottom {
  background: #f9e602;
  border-radius: 0 0 50% 50%;
  height: 25px;
}

@media (width >= 768px) {
  .curved-bottom {
    height: 35px;
  }
}

@media (width >= 1024px) {
  .curved-bottom {
    height: 50px;
  }
}

.learn-play-watch {
  padding: 40px 15px;
}

@media (width >= 768px) {
  .learn-play-watch {
    padding: 80px 15px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch {
    padding: 115px 20px;
  }
}

.learn-play-watch.kids-app {
  padding: 30px 15px;
}

@media (width >= 768px) {
  .learn-play-watch.kids-app {
    padding: 50px 15px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch.kids-app {
    padding: 70px 20px;
  }
}

.learn-play-watch h1 {
  margin: 0 auto 10px;
  font: 700 30px / 1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .learn-play-watch h1 {
    margin: 0 auto 15px;
    font-size: 46px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch h1 {
    font-size: 64px;
  }
}

.learn-play-watch h2 {
  margin: 0 auto;
  font: 400 18px / 1.3em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .learn-play-watch h2 {
    font-size: 29px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch h2 {
    font-size: 39px;
  }
}

.learn-play-watch img {
  width: 100%;
  max-width: 330px;
  margin: 25px auto;
}

@media (width >= 768px) {
  .learn-play-watch img {
    max-width: 630px;
    margin: 35px auto;
  }
}

@media (width >= 1024px) {
  .learn-play-watch img {
    max-width: 760px;
    margin: 45px auto 35px;
  }
}

.learn-play-watch p {
  width: 100%;
  max-width: 330px;
  font: 400 13px / 1.7em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .learn-play-watch p {
    max-width: 100%;
    line-height: 2em;
  }
}

@media (width >= 1024px) {
  .learn-play-watch p {
    font-size: 14px;
  }
}

.learn-play-watch p strong {
  font-size: 14px;
  line-height: 1.2em;
  display: inline-block;
}

@media (width >= 768px) {
  .learn-play-watch p strong {
    font-size: 16px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch p strong {
    font-size: 19px;
  }
}

.learn-play-watch .topic-wrap {
  margin: 0 auto 60px;
}

@media (width >= 768px) {
  .learn-play-watch .topic-wrap {
    margin: 0 auto 110px;
  }
}

.learn-play-watch .topic-wrap:last-child {
  margin: 0 auto;
}

@media (width >= 768px) {
  .learn-play-watch .topic-wrap.play img {
    max-width: 720px;
  }
}

@media (width >= 1024px) {
  .learn-play-watch .topic-wrap.play img {
    max-width: 860px;
  }
}

.learn-play-watch .topic-wrap.watch img {
  margin-bottom: 5px;
}

@media (width >= 1024px) {
  .learn-play-watch .topic-wrap.watch img {
    max-width: 920px;
  }
}

.music-fun-go {
  padding: 30px 15px 40px;
}

@media (width >= 768px) {
  .music-fun-go {
    padding: 60px 15px 70px;
  }
}

@media (width >= 1024px) {
  .music-fun-go {
    padding: 70px 20px 90px;
  }
}

.music-fun-go .toggle-button {
  color: #8c9698;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #e5e8e8;
  border: 4px solid #fff;
  border-radius: 10px;
  width: 110px;
  margin: 0;
  padding: 20px 0 15px;
  transition: all .5s;
  display: inline-block;
}

@media (width >= 768px) {
  .music-fun-go .toggle-button {
    width: 190px;
    margin: 0 5px;
    padding: 25px 0 20px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .toggle-button {
    width: 250px;
    padding: 35px 0 20px;
  }
}

.music-fun-go .toggle-button.active, .music-fun-go .toggle-button.active:hover {
  color: #fff;
  background: #b500ff;
  border-color: #49b0d3;
}

.music-fun-go .toggle-button.active img, .music-fun-go .toggle-button.active:hover img {
  filter: invert();
}

.music-fun-go .toggle-button:hover {
  color: #99a2a4;
  background: #c433ff;
  border-color: #c433ff;
}

.music-fun-go .toggle-button:hover img {
  filter: invert() sepia() saturate(2) hue-rotate(140deg) brightness(.65);
}

.music-fun-go .toggle-button img {
  filter: invert() sepia() saturate(2) hue-rotate(140deg) brightness(.6);
  width: 100%;
  max-width: 100%;
  height: 45px;
  margin: 0 0 10px;
}

@media (width >= 768px) {
  .music-fun-go .toggle-button img {
    height: 60px;
    margin: 0 0 13px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .toggle-button img {
    height: 70px;
    margin: 0 0 17px;
  }
}

.music-fun-go .toggle-button p {
  margin: 0 auto;
  font: 900 14px / 1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .music-fun-go .toggle-button p {
    font-size: 23px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .toggle-button p {
    font-size: 32px;
  }
}

.music-fun-go .topic-wrap {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity .5s;
}

.music-fun-go .topic-wrap.active {
  opacity: 1;
  visibility: visible;
  height: 100%;
  min-height: 555px;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap.active {
    min-height: 494px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap.active {
    min-height: 636px;
  }
}

.music-fun-go .topic-wrap h1 {
  margin: 35px auto 10px;
  font: 900 25px / 1.2em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap h1 {
    font-size: 32px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap h1 {
    margin: 45px auto 17px;
    font-size: 39px;
  }
}

.music-fun-go .topic-wrap h2 {
  margin: 0 auto 25px;
  font: 400 13px / 1.7em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap h2 {
    margin: 0 auto 40px;
    font-size: 15px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap h2 {
    margin: 0 auto 50px;
    font-size: 18px;
  }
}

.music-fun-go .topic-wrap img {
  width: 90%;
  max-width: 311px;
  height: auto;
  margin: 0 auto 25px;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap img {
    width: auto;
    max-width: none;
    height: 195px;
    margin: 0 auto 50px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap img {
    height: 310px;
  }
}

.music-fun-go .topic-wrap p {
  margin: 0 auto;
  font: 400 13px / 1.7em Open Sans, sans-serif;
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap p {
    font-size: 14px;
  }
}

.music-fun-go .topic-wrap p strong {
  font-size: 17px;
  font-weight: 900;
  display: inline-block;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap p strong {
    margin: 0 auto 10px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap p strong {
    font-size: 22px;
  }
}

.music-fun-go .topic-wrap p img {
  width: auto;
  max-width: 100%;
  height: 17px;
  margin: 0 auto;
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap p img {
    height: 22px;
  }
}

.music-fun-go .topic-wrap.music img {
  width: 100%;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap.music img {
    width: auto;
    padding-right: 3%;
  }
}

.music-fun-go .topic-wrap.on-the-go img {
  cursor: pointer;
  outline: none;
}

@media (width >= 768px) {
  .music-fun-go .topic-wrap.on-the-go img {
    height: 250px;
  }
}

@media (width >= 1024px) {
  .music-fun-go .topic-wrap.on-the-go img {
    height: 350px;
  }
}

.final-pitch {
  background: #eff0f1;
  padding: 40px 15px 50px;
}

@media (width >= 768px) {
  .final-pitch {
    padding: 60px 0 80px;
  }
}

@media (width >= 1024px) {
  .final-pitch {
    padding: 75px 0 95px;
  }
}

.final-pitch h1 {
  margin: 0 auto 5px;
  font: 700 28px / 1.3em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch h1 {
    margin: 0 auto 45px;
    font-size: 31px;
  }
}

@media (width >= 1024px) {
  .final-pitch h1 {
    font-size: 39px;
  }
}

.final-pitch h1 sup {
  font-size: 50%;
  top: -.8em;
}

.final-pitch p {
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
  font: 400 14px / 1.6em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch p {
    max-width: 100%;
    font-size: 17px;
  }
}

@media (width >= 1024px) {
  .final-pitch p {
    font-size: 19px;
  }
}

.final-pitch p a {
  color: inherit;
}

.final-pitch p.small {
  font-size: 12px;
}

.final-pitch .download-badge img {
  width: 150px;
  margin: 0 4px 20px;
}

@media (width >= 768px) {
  .final-pitch .download-badge img {
    width: 160px;
    margin: 0 15px 30px;
  }
}

@media (width >= 1024px) {
  .final-pitch .download-badge img {
    width: 180px;
  }
}

.final-pitch .option-wrap {
  border: 0 solid #fff;
  border-bottom-width: 2px;
  padding: 30px 0;
}

@media (width >= 768px) {
  .final-pitch .option-wrap {
    border-width: 0 2px 0 0;
    padding: 27px 0;
  }
}

.final-pitch .option-wrap:last-child {
  border: none;
  padding-bottom: 0;
}

.final-pitch .option-wrap p {
  color: #666;
  margin: 0 auto 27px;
  font: 400 14px / 1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch .option-wrap p {
    font-size: 16px;
  }
}

.final-pitch .option-wrap h4 {
  margin: 0 auto;
  font: 400 16px / 1.4em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch .option-wrap h4 {
    font-size: 17px;
  }
}

@media (width >= 1024px) {
  .final-pitch .option-wrap h4 {
    font-size: 19px;
  }
}

.final-pitch .option-wrap .download-badge img {
  width: 150px;
  margin: 30px 3px 0;
}

@media (width >= 768px) {
  .final-pitch .option-wrap .download-badge img {
    width: 160px;
  }
}

@media (width >= 1024px) {
  .final-pitch .option-wrap .download-badge img {
    width: 180px;
    margin: 30px 7px 0;
  }
}

.final-pitch .option-wrap .join {
  margin: 30px 0 0;
  padding: 16px 30px;
  font-size: 16px;
}

@media (width >= 1024px) {
  .final-pitch .option-wrap .join {
    padding: 17px 30px;
    font-size: 20px;
  }
}

.final-pitch.kids-app {
  color: #fff;
  background: #49b0d3;
}

.final-pitch.kids-app h1 {
  margin: 0 auto 20px;
}

@media (width >= 768px) {
  .final-pitch.kids-app h1 {
    margin: 0 auto 50px;
  }
}

.final-pitch.kids-app .download-badge img {
  width: 160px;
}

@media (width >= 768px) {
  .final-pitch.kids-app .download-badge img {
    width: 190px;
    margin: 0 10px 45px;
  }
}

@media (width >= 1024px) {
  .final-pitch.kids-app .download-badge img {
    width: 210px;
  }
}

.final-pitch.kids-app p.small {
  max-width: 310px;
}

@media (width >= 768px) {
  .final-pitch.kids-app p.small {
    max-width: 100%;
    line-height: 2em;
  }
}

.reveal-overlay {
  z-index: 2147483002;
  background-color: #000c;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.reveal-overlay:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  content: "";
  color: #fff;
  z-index: 1;
  opacity: .8;
  text-align: center;
  outline: none;
  width: 35px;
  margin: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width >= 768px) {
  .reveal-overlay:after {
    width: 50px;
    font-size: 50px;
    top: 7px;
    right: 7px;
  }
}

.reveal-overlay .reveal {
  z-index: 1006;
  backface-visibility: hidden;
  width: 95%;
  max-width: 75rem;
  height: inherit;
  background-color: #fefefe;
  border: none;
  border-radius: 7px;
  outline: none;
  min-height: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  display: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}

@media (width >= 768px) {
  .reveal-overlay .reveal {
    margin: 0 auto;
    left: auto;
    right: auto;
  }
}
/*# sourceMappingURL=sales-app.css.map */
