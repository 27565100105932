@use "../variables" as *;
@use "sass:color";


$kidsPurple: #b500ff;
$kidsBlue: #49b0d3;
$kidsYellow: #f9e602;

h1, h2, h3, h4, h5, h6 {
  font-family: $opensans;
}

@-webkit-keyframes stars {
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 1500px 50%;
  }
}

@keyframes stars {
  from {
    background-position: 0% 50%;
  }
  to {
    background-position: 1500px 50%;
  }
}
img {
  display: inline-block;
}

.trailer-section {
  background: #1a1c1d;
  color: #FFF;
  padding: 40px 0 0;
  @include tablet {
    padding: 50px 0 0;
  }

  p {
    text-transform: uppercase;
    color: #666;
    font-size: 14px;
    @include tablet {
      font-size: 16px;

    }

    img {
      margin: 0 5px 0 0;
      height: 25px;
      vertical-align: bottom;
      @include tablet {
        margin: 0 7px 0 0;
        height: 30px;
      }
    }
  }

  h1 {
    font: 400 19px/1.1em $opensans;
    margin: 0 auto 25px;
    @include tablet {
      font-size: 30px;
      margin: 0 auto 35px;
    }
    @include desktop {
      font-size: 38px;
    }

    strong {
      font-size: 36px;
      line-height: 1.1em;
      @include tablet {
        font-size: 60px;
      }
      @include desktop {
        font-size: 80px;
      }
    }
  }

  .ipad-phone {
    position: relative;
    margin: 0 auto -10px;
    width: 100%;
    max-width: 330px;
    @include tablet {
      max-width: 560px;
      margin: 0 auto -20px;
    }
    @include desktop {
      max-width: 710px;
      margin: 0 auto -25px;
    }

    .preview {
      position: absolute;
      margin: 0;
      transform: translate(-50%, -50%);
      top: 35%;
      left: 27%;
      width: 110px;
      @include tablet {
        left: 31%;
        width: 130px;
      }
      @include desktop {
        top: 37%;
        left: 32%;
        width: 140px;
      }
    }

    i {
      position: absolute;
      top: 48%;
      left: 42%;
      transform: translate(-50%, -50%);
      background: $drumeoBlue;
      border-radius: 50%;
      text-align: center;
      border: 3px solid #fff;
      outline: none;
      cursor: pointer;
      font-size: 25px;
      line-height: 52px;
      width: 58px;
      height: 58px;
      @include tablet {
        font-size: 35px;
        line-height: 72px;
        width: 78px;
        height: 78px;
      }

      &:hover {
    background: color.adjust($drumeoBlue, $lightness: 10%);
      }
    }
  }

  &.kids-app {
    background: #13101e;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      -webkit-animation: stars 100s linear infinite;
      animation: stars 100s linear infinite;
      height: 100%;
      background: url(https://dpwjbsxqtam5n.cloudfront.net/app/for-kids/stars.jpg) center top/1000px;
      @include tablet {
        background-size: 1140px;
      }
    }

    h1 {
      font: 900 28px/1.2em $opensans;
      margin: 0 auto 25px;
      @include tablet {
        font-size: 36px;
        margin: 0 auto 35px;
      }
      @include desktop {
        font-size: 46px;
        margin: 0 auto 47px;
      }
    }

    p img {
      margin: 0 0 5px;
      height: 25px;
      @include tablet {
        height: 38px;
      }
    }

    .ipad-phone {
      .preview {
        left: 57%;
        @include tablet {
          left: 53%;
        }
      }

      i {
        background: $kidsPurple;

        &:hover {
          background: color.adjust($kidsPurple, $lightness: 10%);
        }
      }
    }

    .anchored-image {
      position: absolute;
      height: 60%;
      @include desktop {
        height: 80%;
      }

      &.left-image {
        left: 0;
        bottom: -30px;
      }

      &.right-image {
        top: 0;
        right: 0;
      }
    }
  }
}

.download-pitch {
  background: #eff0f1;
  padding: 30px 15px 40px;
  @include tablet {
    padding: 40px 0;
  }

  p {
    font: 400 14px/1.6em $opensans;
    margin: 0 auto;
    width: 100%;
    max-width: 270px;
    @include tablet {
      font-size: 17px;
      max-width: 100%;
    }
    @include desktop {
      font-size: 19px;
    }

    a {
      color: inherit;
    }

    &.small {
      font-size: 12px;
    }
  }

  .download-badge img {
    margin: 20px 4px;
    width: 150px;
    @include tablet {
      width: 160px;
      margin: 30px 15px;
    }
    @include desktop {
      width: 180px;
    }
  }

  &.kids-app {
    background: $kidsYellow;
    padding-bottom: 0;
  }

}

.curved-bottom {
  background: $kidsYellow;
  border-radius: 0 0 50% 50%;
  height: 25px;
  @include tablet {
    height: 35px;
  }
  @include desktop {
    height: 50px;
  }
}

.learn-play-watch {
  padding: 40px 15px;
  @include tablet {
    padding: 80px 15px;
  }
  @include desktop {
    padding: 115px 20px;
  }

  &.kids-app {
    padding: 30px 15px;
    @include tablet {
      padding: 50px 15px;
    }
    @include desktop {
      padding: 70px 20px;
    }
  }

  h1 {
    font: 700 30px/1em $opensans;
    margin: 0 auto 10px;
    @include tablet {
      font-size: 46px;
      margin: 0 auto 15px;
    }
    @include desktop {
      font-size: 64px;
    }
  }

  h2 {
    font: 400 18px/1.3em $opensans;
    margin: 0 auto;
    @include tablet {
      font-size: 29px;
    }
    @include desktop {
      font-size: 39px;
    }
  }

  img {
    width: 100%;
    max-width: 330px;
    margin: 25px auto;
    @include tablet {
      max-width: 630px;
      margin: 35px auto;
    }
    @include desktop {
      max-width: 760px;
      margin: 45px auto 35px;
    }
  }

  p {
    font: 400 13px/1.7em $opensans;
    width: 100%;
    max-width: 330px;
    @include tablet {
      line-height: 2em;
      max-width: 100%;
    }
    @include desktop {
      font-size: 14px;
    }

    strong {
      line-height: 1.2em;
      display: inline-block;
      font-size: 14px;
      @include tablet {
        font-size: 16px;
      }
      @include desktop {
        font-size: 19px;
      }
    }
  }

  .topic-wrap {
    margin: 0 auto 60px;
    @include tablet {
      margin: 0 auto 110px;
    }

    &:last-child {
      margin: 0 auto;
    }

    &.play img {
      @include tablet {
        max-width: 720px;
      }
      @include desktop {
        max-width: 860px;
      }
    }

    &.watch img {
      margin-bottom: 5px;
      @include desktop {
        max-width: 920px;
      }
    }
  }
}

.music-fun-go {
  padding: 30px 15px 40px;
  @include tablet {
    padding: 60px 15px 70px;
  }
  @include desktop {
    padding: 70px 20px 90px;
  }

  .toggle-button {
    border-radius: 10px;
    display: inline-block;
    color: #8c9698;
    background: #e5e8e8;
    border: 4px solid #fff;
    transition: all .5s;
    cursor: pointer;
    user-select: none;
    margin: 0;
    width: 110px;
    padding: 20px 0 15px;
    @include tablet {
      width: 190px;
      padding: 25px 0 20px;
      margin: 0 5px;
    }
    @include desktop {
      width: 250px;
      padding: 35px 0 20px;

    }

    &.active, &.active:hover {
      color: #fff;
      background: $kidsPurple;
      border-color: $kidsBlue;

      img {
        filter: invert(1);
      }
    }

    &:hover {
      background: color.adjust($kidsPurple, $lightness: 10%);
      border-color: color.adjust($kidsPurple, $lightness: 10%);
      color: color.adjust(#8c9698, $lightness: 5%);

      img {
        filter: invert(1) sepia(1) saturate(2) hue-rotate(140deg) brightness(0.65);
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      filter: invert(1) sepia(1) saturate(2) hue-rotate(140deg) brightness(0.6);
      height: 45px;
      margin: 0 0 10px;
      @include tablet {
        height: 60px;
        margin: 0 0 13px;
      }
      @include desktop {
        height: 70px;
        margin: 0 0 17px;

      }
    }

    p {
      font: 900 14px/1em $opensans;
      margin: 0 auto;
      @include tablet {
        font-size: 23px;
      }
      @include desktop {
        font-size: 32px;
      }
    }
  }


  .topic-wrap {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: opacity .5s;

    &.active {
      opacity: 1;
      visibility: visible;
      height: 100%;
      min-height: 555px;
      @include tablet {
        min-height: 494px;
      }
      @include desktop {
        min-height: 636px;
      }
    }

    h1 {
      font: 900 25px/1.2em $opensans;
      margin: 35px auto 10px;
      @include tablet {
        font-size: 32px;
      }
      @include desktop {
        font-size: 39px;
        margin: 45px auto 17px;
      }
    }

    h2 {
      font: 400 13px/1.7em $opensans;
      margin: 0 auto 25px;
      @include tablet {
        font-size: 15px;
        margin: 0 auto 40px;
      }
      @include desktop {
        font-size: 18px;
        margin: 0 auto 50px;
      }
    }

    img {
      height: auto;
      width: 90%;
      max-width: 311px;
      margin: 0 auto 25px;
      @include tablet {
        max-width: none;
        width: auto;
        height: 195px;
        margin: 0 auto 50px;
      }
      @include desktop {
        height: 310px;
      }
    }

    p {
      font: 400 13px/1.7em $opensans;
      margin: 0 auto;
      @include desktop {
        font-size: 14px;
      }

      strong {
        font-size: 17px;
        font-weight: 900;
        display: inline-block;
        @include tablet {
          margin: 0 auto 10px;
        }
        @include desktop {
          font-size: 22px;
        }
      }

      img {
        width: auto;
        max-width: 100%;
        margin: 0 auto;
        height: 17px;
        @include desktop {
          height: 22px;
        }
      }
    }

    &.music img {
      width: 100%;
      @include tablet {
        width: auto;
        padding-right: 3%;
      }
    }

    &.on-the-go img {
      outline: none;
      cursor: pointer;
      @include tablet {
        height: 250px;
      }
      @include desktop {
        height: 350px;
      }
    }
  }

}

.final-pitch {
  background: #eff0f1;
  padding: 40px 15px 50px;
  @include tablet {
    padding: 60px 0 80px;
  }
  @include desktop {
    padding: 75px 0 95px;
  }

  h1 {
    font: 700 28px/1.3em $opensans;
    margin: 0 auto 5px;
    @include tablet {
      font-size: 31px;
      margin: 0 auto 45px;
    }
    @include desktop {
      font-size: 39px;
    }

    sup {
      font-size: 50%;
      top: -0.8em;
    }
  }

  p {
    font: 400 14px/1.6em $opensans;
    margin: 0 auto;
    width: 100%;
    max-width: 270px;
    @include tablet {
      font-size: 17px;
      max-width: 100%;
    }
    @include desktop {
      font-size: 19px;
    }

    a {
      color: inherit;
    }

    &.small {
      font-size: 12px;
    }
  }

  .download-badge img {
    margin: 0 4px 20px;
    width: 150px;
    @include tablet {
      width: 160px;
      margin: 0 15px 30px;
    }
    @include desktop {
      width: 180px;
    }
  }

  .option-wrap {
    padding: 30px 0;
    border: 2px solid #fff;
    border-width: 0 0 2px;
    @include tablet {
      border-width: 0 2px 0 0;
      padding: 27px 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    p {
      margin: 0 auto 27px;
      color: #666;
      font: 400 14px/1em $opensans;
      @include tablet {
        font-size: 16px;
      }
    }

    h4 {
      font: 400 16px/1.4em $opensans;
      margin: 0 auto;
      @include tablet {
        font-size: 17px;
      }
      @include desktop {
        font-size: 19px;
      }
    }

    .download-badge img {
      margin: 30px 3px 0;
      width: 150px;
      @include tablet {
        width: 160px;
      }
      @include desktop {
        margin: 30px 7px 0;
        width: 180px;
      }
    }

    .join {
      margin: 30px 0 0;
      font-size: 16px;
      padding: 16px 30px;
      @include desktop {
        font-size: 20px;
        padding: 17px 30px;
      }
    }
  }

  &.kids-app {
    background: $kidsBlue;
    color: #fff;

    h1 {
      margin: 0 auto 20px;
      @include tablet {
        margin: 0 auto 50px;
      }
    }

    .download-badge img {
      width: 160px;
      @include tablet {
        width: 190px;
        margin: 0 10px 45px;
      }
      @include desktop {
        width: 210px;
      }
    }

    p.small {
      max-width: 310px;
      @include tablet {
        max-width: 100%;
        line-height: 2em;
      }
    }

  }
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483002;
  display: none;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8);

  &:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    content: "\f00d";
    color: #fff;
    z-index: 1;
    opacity: 0.8;
    position: absolute;
    margin: 0;
    line-height: 1em;
    text-align: center;
    display: inline-block;
    outline: none;
    top: 0;
    right: 0;
    font-size: 35px;
    width: 35px;
    @include tablet {
      top: 7px;
      right: 7px;
      font-size: 50px;
      width: 50px;
    }
  }

  .reveal {
    z-index: 1006;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: none;
    background-color: #fefefe;
    position: relative;
    top: 100px;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    width: 95%;
    max-width: 75rem;
    height: inherit;
    min-height: 0;
    outline: none;
    padding: 0;
    border: none;
    border-radius: 7px;
    @include tablet {
      right: auto;
      left: auto;
      margin: 0 auto;
    }
  }
}
